* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #dedede;
  font-family: 'PT Sans', sans-serif;
  overflow-x: hidden;
}

a {
  color: #313131;
}

a:hover {
  color: #313131;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 1.5rem;
  font-weight: bold;
  border: 0;
  appearance: none;
}

.button-red, .button-blue {
  background-color: #2f429f;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.6rem 1rem;
  border: 0;
  border-radius: 4px;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.button-red--center, .button-blue--center {
  align-self: center;
}

.button-red--right, .button-blue--right {
  align-self: flex-end;
}

.button-red--small, .button-blue--small {
  padding: 0.2rem 0.6rem;
}

.button-red--small .button__icon, .button-blue--small .button__icon {
  width: 10px;
  margin-right: 3px;
  top: -1px;
  position: relative;
}

.button-red:hover, .button-blue:hover {
  color: #fff;
  text-decoration: none;
}

.button-red {
  background-color: #f44336;
}

.button-red--left {
  margin-left: 15px;
}

.button__icon {
  width: 15px;
  margin-right: 8px;
}

.button__icon--alone {
  margin-right: 0;
  width: 25px;
}

.button__image {
  width: 15px;
  margin-left: 10px;
}

.button__image--left {
  margin-right: 10px;
  margin-left: 0;
}

.button__between {
  display: flex;
  justify-content: space-between;
}

.button__group {
  display: flex;
}

.button__group .button-blue {
  margin-left: 15px;
}

.button--blue {
  background-color: #2d3f7d;
  color: #fff;
}

.button--black {
  background-color: #313131;
  color: #fff;
}

.button--lightblue {
  background-color: #64bef0;
  color: #fff;
}

.button--grey {
  background-color: #eeeeee;
}

.button:hover {
  color: #fff;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.footer__container {
  background-color: #313131;
}

.footer__image {
  width: 100px;
  background-color: #fff;
  padding: 5px;
}

.footer__links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__links li {
  margin-left: 1rem;
  color: #fff;
}

.footer__links a {
  color: #fff;
}

.footer__links a:hover {
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  height: 100px;
}

.header__container {
  background-color: #fff;
  border-bottom: 8px solid #2d3f7d;
}

.header .logo {
  width: 300px;
}

.header--right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  flex: 1;
}

.header--right a {
  margin-left: 1rem;
  font-weight: bold;
}

.hero {
  position: relative;
  height: 500px;
  padding-top: 3rem;
}

.hero > .container {
  position: relative;
}

.hero__image {
  object-fit: cover;
  width: 100%;
  display: block;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero__block {
  background-color: #fff;
  padding: 2rem 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.14);
  width: 350px;
}

.hero__block--right {
  width: 500px;
  position: absolute;
  right: 0;
}

.hero__block .button {
  margin-top: 1rem;
}

.hero__title {
  color: #2d3f7d;
  font-weight: bold;
  font-size: 1.8rem;
}

.hero__description {
  font-size: 1.1rem;
}

.hero--large {
  height: calc(100vh - 300px);
}

.hero--large .hero__image {
  height: calc(100vh - 300px);
}

.slider {
  background-color: #fff;
  padding: 2rem 0;
}

.slider__title {
  text-align: center;
  color: #2d3f7d;
  font-weight: bold;
  font-size: 1.6rem;
  width: 420px;
  margin: 0 auto 2rem auto;
  display: block;
}

.slider__container {
  position: relative;
  margin: 3rem 0 2rem;
}

.slider__arrow {
  background: transparent;
  outline: 0;
  border: 0;
  width: 50px;
  position: absolute;
  top: 50%;
  left: -80px;
  transform: translateY(-50%);
}

.slider__arrow img {
  width: 50px;
}

.slider__arrow--left {
  transform: translateY(-50%) rotate(180deg);
}

.slider__arrow--right {
  right: -80px;
  left: auto;
}

.slider--blue {
  background-color: #2f429f;
}

.slider--blue .slider__title {
  color: #fff;
}

.slider--blue .slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.slider--blue .slider__item img {
  height: 100px;
}

.slider--blue .slider__subtitle {
  display: block;
  padding: 0 25%;
  line-height: 1;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
}

.slider--full .slider__item {
  padding: 0 10px;
  position: relative;
  margin-bottom: 20px;
}

.slider--full .slider__item .slider__background {
  width: 100%;
  height: auto;
  border: 2px solid #fff;
}

.slider--full .slider__item a {
  display: block;
}

.slider--full .slider__item:hover .slider__background {
  border: 2px solid #2d3f7d;
}

.slider--full .slider__item:hover .slider__popup {
  opacity: 1;
  transform: translate(-50%, 50%);
}

.slider--full .slider__popup {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  font-weight: bold;
  background: #313131;
  color: #fff;
  transform: translate(-50%, 0%);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 0;
  line-height: 1;
  width: 180px;
  transition: 0.2s transform ease, 0.1s opacity ease;
}

.slider--full .slider__popup img {
  width: 15px;
  margin-left: 10px;
}

.slider--full .slider__popup--left {
  margin-left: 0 !important;
  margin-right: 10px;
}

.slider--products {
  background-color: #e6e6e6;
}

.slider--products .slider__product {
  background-color: #fff;
  border: 2px solid #fff;
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.slider--products .slider__item {
  padding: 0 10px;
  position: relative;
  margin-bottom: 20px;
  text-decoration: none;
}

.slider--products .slider__item .slider__background {
  height: 150px;
  display: block;
  width: auto;
}

.slider--products .slider__item p {
  margin-bottom: 0;
  margin-top: 2rem;
  color: #2d3f7d;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0 50px;
  text-align: center;
  transform: translateY(0);
  transition: 0.2s transform ease-in-out;
}

.slider--products .slider__item:hover .slider__product {
  border: 2px solid #2d3f7d;
}

.slider--products .slider__item:hover .slider__product p {
  transform: translateY(-10px);
}

.slider--products .slider__item:hover .slider__popup {
  opacity: 1;
  transform: translate(-50%, 50%);
}

.slider--products .slider__popup {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  font-weight: bold;
  background: #313131;
  color: #fff;
  transform: translate(-50%, 0%);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 0;
  line-height: 1;
  width: 180px;
  transition: 0.2s transform ease, 0.1s opacity ease;
}

.slider--products .slider__popup img {
  width: 15px;
  margin-left: 10px;
}

.slider--products .slider__popup--left {
  margin-left: 0 !important;
  margin-right: 10px;
}

.slider--products .slick-track {
  display: flex;
}

.slider--products .slick-slide {
  height: auto;
}

.modal__image {
  width: 100%;
  display: block;
  height: auto;
}

.modal__content {
  padding: 2rem;
  color: #313131;
  background-color: #fff;
}

.modal .button {
  margin-top: 1rem;
}

.main {
  min-height: calc(100vh - 108px);
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.bloc--grey {
  background-color: #e6e6e6;
  padding: 1rem 2rem;
  max-width: 500px;
  margin: 0 auto;
}

.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);
  border: 0;
  width: 100%;
  padding: 2rem 3rem;
}

.card--stretch {
  width: 500px;
}

.card--small {
  width: 350px;
}

.card--left {
  align-self: flex-start;
}

.card--right {
  align-self: flex-end;
  text-align: right;
}

.card--list {
  flex-flow: row nowrap;
  padding-left: 0;
  padding-right: 0;
}

.card__info {
  margin-bottom: 0;
}

.card__info--big {
  font-size: 1.2rem;
}

.card__inner {
  flex-basis: 33%;
  padding: 0 2rem;
  border-right: 1px solid #e6e6e6;
}

.card__inner:last-of-type {
  border-right: 0;
}

.card .form__subtitle {
  margin-top: 0;
}

.table {
  margin-bottom: 0;
}

.table-responsive {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);
}

.table__actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.table__actions .button-blue {
  font-size: 0.8rem;
}

.table__price {
  width: 130px;
}

.table thead {
  background: #2f429f;
}

.table thead th {
  border: 0;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  height: 70px;
  vertical-align: middle;
  padding: 0 1rem;
  border-right: 2px solid #fff;
}

.table thead th:last-child {
  border-right: 0;
}

.table tbody td,
.table tbody th {
  border: 0;
  border-right: 2px solid #e6e6e6;
  padding: 1.5rem;
  color: #313131;
}

.table tbody td:last-child,
.table tbody th:last-child {
  border-right: 0;
}

.table tbody tr:nth-child(2n) {
  background: #fafafa;
}

.kt-pagination {
  padding: 0rem 2rem;
}

.kt-pagination__links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.kt-pagination__links li {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);
}

.kt-pagination__links li:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.kt-pagination__links li:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.kt-pagination__links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.kt-pagination__links li a:hover {
  text-decoration: none;
}

.kt-pagination__link--active {
  background-color: #2d3f7d !important;
}

.kt-pagination__link--active a {
  color: #fff !important;
}

.form--full {
  flex: 1;
  width: 100%;
}

.form__input, .form__textarea {
  display: block;
  margin: 0.5rem 0;
  border: 0;
  appearance: none;
  height: 50px;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #e6e6e6;
  background: #fff;
}

.form__subtitle {
  color: #2d3f7d;
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.form__textarea {
  padding: 0.8rem 1rem;
  height: 100px;
}

.search {
  max-width: 400px;
  margin-bottom: 2rem;
}

.search__label {
  color: #a2a2a2;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.3em;
}

.content {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  padding: 3rem 2rem;
}

.title {
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
}

.urban__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  font-weight: bold;
  background: #313131;
  color: #fff;
  transform: translate(-50%, 0%);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 0;
  line-height: 1;
  width: 180px;
  transition: 0.2s transform ease, 0.1s opacity ease;
}

.urban__button img {
  width: 15px;
  margin-left: 10px;
}

.urban__button--left {
  margin-left: 0 !important;
  margin-right: 10px;
}

.urban__heading {
  color: #313131;
  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 1px;
}

.urban__card {
  padding: 30px 20px 50px;
  border: 1px solid transparent;
  text-decoration: none;
}

.urban__card:hover {
  border: 1px solid #2d3f7d;
  text-decoration: none;
}

.urban__card:hover .urban__button {
  opacity: 1;
  transform: translate(-50%, 50%);
}

.urban__card--detail {
  padding-bottom: 30px;
  border: 0;
}

.urban__card--detail:hover {
  border: 0;
}

.urban__image {
  border: 1px solid lightgrey;
  border-radius: 6px;
}

.urban__image img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.urban__title {
  color: #2d3f7d;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 10px;
}

.urban__reference {
  color: #313131;
  font-weight: 500;
  font-size: .9em;
}

.urban__flex {
  display: flex;
}

.urban__flex .urban__title {
  margin-bottom: 0;
}

.urban__left {
  border: 1px solid lightgrey;
  margin-right: 1em;
}

.urban__left img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.urban__right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.urban__buttons {
  flex: 1;
}

.urban__buttons .button,
.urban__buttons .button-blue,
.urban__buttons .urban__input {
  align-self: flex-end;
}

.urban__input {
  height: 38px;
  margin-right: .5em;
  border-radius: 4px;
  border: 1px solid lightgrey;
  text-indent: 10px;
}

.urban__select {
  margin-right: .5em;
}

.form__urban__selector {
  position: absolute;
  height: 200px;
  overflow: scroll;
  width: 300px;
  padding-top: 10px;
  background-color: #fff;
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

.form__urban__container {
  position: relative;
  align-self: flex-end;
}

.form__urban__close {
  appearance: none;
  background: none;
  border: 0;
  font-size: 2em;
  position: sticky;
  top: 0;
  right: 5px;
  height: 40px;
  width: 50px;
  margin-top: -50px;
  align-self: flex-end;
}

.form__urban__close:focus {
  outline: none;
}

.select-title {
  color: #283c97;
  font-size: 16px;
  padding: 7px 12px 3px 12px;
  pointer-events: none;
}

.select-item {
  height: 30px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 0;
}

.select-item.selected,
.select-item:hover {
  background-color: #e4e4e4;
}

.select-item input {
  display: none;
}

.select-preview {
  margin-right: 8px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.select-preview--small {
  margin-right: 8px;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  border: 2px solid white;
}
