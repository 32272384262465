@import './colors.scss';

.hero {
  position: relative;
  height: 500px;
  padding-top: 3rem;

  > .container {
    position: relative;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    display: block;
    height: 600px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__block {
    background-color: $white;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.14);
    width: 350px;

    &--right {
      width: 500px;
      position: absolute;
      right: 0;
    }

    .button {
      margin-top: 1rem;
    }
  }

  &__title {
    color: $blue;
    font-weight: bold;
    font-size: 1.8rem;
  }

  &__description {
    font-size: 1.1rem;
  }

  &--large {
    height: calc(100vh - 300px);

    .hero__image {
      height: calc(100vh - 300px);
    }
  }
}
