@import './colors.scss';

.content {
  background: $white;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;

  padding: 3rem 2rem;
}

.title {
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
}
