@import './colors.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  height: 100px;

  &__container {
    background-color: $white;
    border-bottom: 8px solid $blue;
  }

  .logo {
    width: 300px;
  }

  &--right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    flex: 1;

    a {
      margin-left: 1rem;
      font-weight: bold;
    }
  }
}
