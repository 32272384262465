@import './colors.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgb(222, 222, 222);
  font-family: 'PT Sans', sans-serif;
  overflow-x: hidden;
}

a {
  color: $black;

  &:hover {
    color: $black;
  }
}

@import './buttons.scss';
@import './footer.scss';
@import './header.scss';
@import './hero.scss';
@import './sliders.scss';
@import './modal.scss';
@import './blocks.scss';
@import './form.scss';
@import './content.scss';
@import './urban.scss';
