@import './colors';

.main {
  min-height: calc(100vh - 108px);
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  &--background {
    // background: red;
  }
}

.bloc {
  &--grey {
    background-color: $grey;
    padding: 1rem 2rem;
    max-width: 500px;
    margin: 0 auto;
  }
}

.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);
  border: 0;
  width: 100%;
  padding: 2rem 3rem;

  &--stretch {
    width: 500px;
  }

  &--small {
    width: 350px;
  }

  &--left {
    align-self: flex-start;
  }

  &--right {
    align-self: flex-end;
    text-align: right;
  }

  &--list {
    flex-flow: row nowrap;
    padding-left: 0;
    padding-right: 0;
  }

  &__info {
    margin-bottom: 0;

    &--big {
      font-size: 1.2rem;
    }
  }

  &__inner {
    flex-basis: 33%;
    padding: 0 2rem;
    border-right: 1px solid $grey;

    &:last-of-type {
      border-right: 0;
    }
  }

  .form__subtitle {
    margin-top: 0;
  }
}

.table {
  margin-bottom: 0;

  &-responsive {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .button-blue {
      font-size: 0.8rem;
    }
  }

  &__price {
    width: 130px;
  }

  thead {
    background: $bgblue;

    th {
      border: 0;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      height: 70px;
      vertical-align: middle;
      padding: 0 1rem;
      border-right: 2px solid $white;

      &:last-child {
        border-right: 0;
      }
    }
  }

  tbody {
    td,
    th {
      border: 0;
      border-right: 2px solid $grey;
      padding: 1.5rem;
      color: $black;

      &:last-child {
        border-right: 0;
      }
    }

    tr {
      &:nth-child(2n) {
        background: darken($white, 2);
      }
    }
  }
}

.kt-pagination {
  padding: 0rem 2rem;

  &__links {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;

    li {
      width: 40px;
      height: 40px;
      background-color: $white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.1);

      &:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__link--active {
    background-color: $blue !important;
    
    a {
      color: $white !important;
    }
  }
}
