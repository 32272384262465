@import './colors.scss';

.form {
  &--full {
    flex: 1;
    width: 100%;
  }

  &__input {
    display: block;
    margin: 0.5rem 0;
    border: 0;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0 1rem;
    border: 1px solid $grey;
    background: $white;
  }

  &__subtitle {
    color: $blue;
    font-size: 1.2rem;
    margin-top: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__textarea {
    @extend .form__input;
    padding: 0.8rem 1rem;
    height: 100px;
  }
}

.search {
  max-width: 400px;
  margin-bottom: 2rem;

  &__label {
    color: #a2a2a2;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.3em;
  }
}