@import './colors.scss';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 1.5rem;
  font-weight: bold;
  border: 0;
  appearance: none;

  &-red,
  &-blue {
    background-color: $bgblue;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.6rem 1rem;
    border: 0;
    border-radius: 4px;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    &--center {
      align-self: center;
    }

    &--right {
      align-self: flex-end;
    }

    &--small {
      padding: 0.2rem 0.6rem;

      .button__icon {
        width: 10px;
        margin-right: 3px;
        top: -1px;
        position: relative;
      }
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &-red {
    background-color: #f44336;

    &--left {
      margin-left: 15px;
    }
  }

  &__icon {
    width: 15px;
    margin-right: 8px;

    &--alone {
      margin-right: 0;
      width: 25px;
    }
  }

  &__image {
    width: 15px;
    margin-left: 10px;

    &--left {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  &__between {
    display: flex;
    justify-content: space-between;
  }

  &__group {
    display: flex;

    .button-blue {
      margin-left: 15px;
    }
  }

  &--blue {
    background-color: $blue;
    color: $white;
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--lightblue {
    background-color: rgb(100, 190, 240);
    color: $white;
  }

  &--grey {
    background-color: #eeeeee;
  }

  &:hover {
    color: $white;
  }
}
