@import './colors.scss';

@mixin makePopup {
  &__popup {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    font-weight: bold;
    background: $black;
    color: $white;
    transform: translate(-50%, 0%);
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 0;
    line-height: 1;

    width: 180px;

    transition: 0.2s transform ease, 0.1s opacity ease;

    img {
      width: 15px;
      margin-left: 10px;
    }

    &--left {
      margin-left: 0 !important;
      margin-right: 10px;
    }
  }
}

.slider {
  background-color: $white;
  padding: 2rem 0;

  &__title {
    text-align: center;
    color: $blue;
    font-weight: bold;
    font-size: 1.6rem;
    width: 420px;
    margin: 0 auto 2rem auto;
    display: block;
  }

  &__container {
    position: relative;
    margin: 3rem 0 2rem;
  }

  &__arrow {
    background: transparent;
    outline: 0;
    border: 0;
    width: 50px;

    position: absolute;
    top: 50%;
    left: -80px;
    transform: translateY(-50%);

    img {
      width: 50px;
    }

    &--left {
      transform: translateY(-50%) rotate(180deg);
    }

    &--right {
      right: -80px;
      left: auto;
    }
  }

  &--blue {
    background-color: $bgblue;

    .slider {
      &__title {
        color: $white;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;

        img {
          height: 100px;
        }
      }

      &__subtitle {
        display: block;
        padding: 0 25%;
        line-height: 1;
        color: $white;
        font-size: 1.2rem;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  &--full {
    .slider {
      &__item {
        padding: 0 10px;
        position: relative;
        margin-bottom: 20px;

        .slider__background {
          width: 100%;
          height: auto;
          border: 2px solid $white;
        }

        a {
          display: block;
        }

        &:hover {
          .slider__background {
            border: 2px solid $blue;
          }

          .slider__popup {
            opacity: 1;
            transform: translate(-50%, 50%);
          }
        }
      }

      @include makePopup();
    }
  }

  &--products {
    background-color: $grey;

    .slider {
      &__product {
        background-color: $white;
        border: 2px solid $white;
        padding: 1rem;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
      }

      &__item {
        padding: 0 10px;
        position: relative;
        margin-bottom: 20px;
        text-decoration: none;

        .slider__background {
          height: 150px;
          display: block;
          width: auto;
        }

        p {
          margin-bottom: 0;
          margin-top: 2rem;
          color: $blue;
          font-size: 1.3rem;
          line-height: 1;
          padding: 0 50px;
          text-align: center;

          transform: translateY(0);
          transition: 0.2s transform ease-in-out;
        }

        &:hover {
          .slider {
            &__product {
              border: 2px solid $blue;

              p {
                transform: translateY(-10px);
              }
            }

            &__popup {
              opacity: 1;
              transform: translate(-50%, 50%);
            }
          }
        }
      }

      @include makePopup();
    }

    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: auto;
    }
  }
}
