@import './colors.scss';

.modal {
  &__image {
    width: 100%;
    display: block;
    height: auto;
  }

  &__content {
    padding: 2rem;
    color: $black;
    background-color: $white;
  }

  .button {
    margin-top: 1rem;
  }
}
