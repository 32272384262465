@import './colors.scss';

@mixin makePopup {
  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    font-weight: bold;
    background: $black;
    color: $white;
    transform: translate(-50%, 0%);
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 0;
    line-height: 1;

    width: 180px;

    transition: 0.2s transform ease, 0.1s opacity ease;

    img {
      width: 15px;
      margin-left: 10px;
    }

    &--left {
      margin-left: 0 !important;
      margin-right: 10px;
    }
  }
}

.urban {
  @include makePopup();

  &__heading {
    color: $black;
    font-size: 1.6em;
    font-weight: bold;
    letter-spacing: 1px;
  }

  &__card {
    padding: 30px 20px 50px;
    border: 1px solid transparent;
    text-decoration: none;

    &:hover {
      border: 1px solid $blue;
      text-decoration: none;

      .urban__button {
        opacity: 1;
        transform: translate(-50%, 50%);
      }
    }

    &--detail {
      padding-bottom: 30px;
      border: 0;

      &:hover {
        border: 0;
      }
    }
  }

  &__image {
    border: 1px solid lightgrey;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }

  &__title {
    color: $blue;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  &__reference {
    color: $black;
    font-weight: 500;
    font-size: .9em;
  }

  &__flex {
    display: flex;

    .urban__title {
      margin-bottom: 0;
    }
  }

  &__left {
    border: 1px solid lightgrey;
    margin-right: 1em;

    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
    }
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    flex: 1;

    .button,
    .button-blue,
    .urban__input {
      align-self: flex-end;
    }
  }

  &__input {
    height: 38px;
    margin-right: .5em;
    border-radius: 4px;
    border: 1px solid lightgrey;
    text-indent: 10px;
  }

  &__select {
    margin-right: .5em;
  }
}

.form__urban {
  &__selector {
    position: absolute;
    height: 200px;
    overflow: scroll;
    width: 300px;
    padding-top: 10px;
    background-color: $white;
    visibility: hidden;
    display: flex;
    flex-direction: column;
  }

  &__container {
    position: relative;
    align-self: flex-end;
  }

  &__close {
    appearance: none;
    background: none;
    border: 0;
    font-size: 2em;
    position: sticky;
    top: 0;
    right: 5px;
    height: 40px;
    width: 50px;
    margin-top: -50px;
    align-self: flex-end;

    &:focus {
      outline: none;
    }
  }
}


.select-title {
  color: #283c97;
  font-size: 16px;
  padding: 7px 12px 3px 12px;
  pointer-events: none;
}

.select-item {
  height: 30px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 0;
}

.select-item.selected,
.select-item:hover {
  background-color: #e4e4e4;
}

.select-item input {
  display: none;
}

.select-preview {
  margin-right: 8px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.select-preview--small {
  margin-right: 8px;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  border: 2px solid white;
}