@import './colors.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  &__container {
    background-color: $black;
  }

  &__image {
    width: 100px;
    background-color: $white;
    padding: 5px;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-left: 1rem;
      color: $white;
    }

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}
